import {
  Box,
  Button,
  ButtonProps,
  Center,
  chakra,
  ComponentWithAs,
  Container,
  CSSObject,
  Flex,
  Img,
} from "@chakra-ui/react"
import { NextImage } from "components/system/NextImage"
import { Wbr } from "components/system/text/wbr"
import { MoniclePlus as MoniclePlusItem } from "lib/microcms/schema/schema"
import { FC } from "react"

const Monicrew: FC<{
  /**
   * 公式サイトのURL　URLパラメータを付けれる。デフォルトは https://media.monicle.co.jp/
   */
  url?: string
  items: Pick<
    MoniclePlusItem,
    "category" | "date" | "thumbnail" | "title" | "url"
  >[]
  buttonProps?: ComponentWithAs<"button", ButtonProps>
  buttonSx?: CSSObject
  containerSx?: CSSObject
}> = ({
  url = "https://media.monicle.co.jp/",
  items,
  buttonProps,
  buttonSx,
  containerSx,
}) => {
  return (
    <Container
      maxW="container.xl"
      py={{ base: 12, lg: 18 }}
      sx={{
        px: { base: 3, lg: 20 },
        ...containerSx,
      }}
    >
      <Box flex={45} textAlign={{ base: "left", lg: "center"}}>
        <Img src="/images/media/logo.svg" w="644px" mx={"auto"} mb={{base: 4, lg: 8}}></Img>
        <chakra.p
          fontWeight={700}
          fontSize="14px"
          lineHeight="32px"
          letterSpacing="0.05em"
        >
          『モニクルー』は、株式会社モニクルが運営する公式オウンドメディアです。
          <br />
          <Wbr> モニクルの社員の働き方やカルチャーに加えて、</Wbr>
          <Wbr>仕事に対する思いや今後実現したい目標について、</Wbr>
          情報発信します。
        </chakra.p>
      </Box>
      <Flex mt={8} overflow="auto" flexWrap="nowrap" gap={{ base: 2, lg: 4 }}>
        {items.map((i) => (
          <Card key={i.title} {...i} />
        ))}
      </Flex>
      <Center>
        <Button
          as="a"
          href={url}
          variant="normal"
          w={{ base: "full", lg: "275px" }}
          size="lg"
          target="_blank"
          rel="noopener noreferrer"
          textStyle="title-bold-14pt"
          mt={{ base: 8, lg: 12 }}
          sx={buttonSx}
          {...buttonProps}
        >
          公式サイト
        </Button>
      </Center>
    </Container>
  )
}

export default Monicrew

const Card: FC<
  Pick<MoniclePlusItem, "category" | "date" | "thumbnail" | "title" | "url">
> = (props) => {
  return (
    <chakra.a
      href={props.url}
      w={{ base: "152px", lg: "225px" }}
      flexShrink={0}
    >
      <Img src={props.thumbnail.url} height={"auto"} width={"full"} />
      <Flex
        my={{ base: 1, lg: 2 }}
        mb={2}
        fontSize={"12px"}
        fontWeight={700}
        lineHeight={"140%"}
        letterSpacing="0.05em"
        gap={3}
      >
        <chakra.span color="point-blue">{props.category}</chakra.span>
        <chakra.span color="3rd-gray">{props.date}</chakra.span>
      </Flex>
      <chakra.p
        fontWeight={500}
        fontSize={"12px"}
        lineHeight="180%"
        letterSpacing="0.05em"
        color="text-main-black"
      >
        {props.title}
      </chakra.p>
    </chakra.a>
  )
}
