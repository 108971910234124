import { Box, chakra, Flex, Link, VStack } from "@chakra-ui/react"
import { PlaceIcon } from "components/icons/icons"
import { imgixImageLoader } from "lib/imgix"
import { Member as MemberSchema } from "lib/microcms/schema/schema"
import Image from "next/image"
import NextLink from "next/link"
import { FC } from "react"

/**
 * メンバー詳細ページへのリンクカード
 */
export const MemberCard: FC<
  Pick<
    MemberSchema,
    | "id"
    | "profilePicture"
    | "title"
    | "givenName"
    | "familyName"
    | "team"
    | "jobTitle"
    | "placeJa"
  >
> = (props) => {
  return (
    <NextLink href={{ pathname: `/${props.id}` }} passHref>
      <Link _hover={{ textDecoration: "none" }}>
        <chakra.div role="group">
          <chakra.div overflow="hidden">
            <chakra.div
              _groupHover={{
                transform: "scale(1.1)",
                transitionDuration: ".4s",
              }}
              transitionDuration=".4s"
            >
              {props.profilePicture && (
                <Image
                  src={props.profilePicture.url}
                  alt="プロフィール写真"
                  width={props.profilePicture.width}
                  height={props.profilePicture.height}
                  loader={imgixImageLoader}
                />
              )}
            </chakra.div>
          </chakra.div>
          <Box py={{ base: 2, lg: 4 }}>
            <chakra.strong
              display="block"
              textAlign="left"
              color="text-main-black"
              mb={{ base: 2, lg: 4 }}
              fontSize={{ base: "13px", lg: "16px" }}
              lineHeight={{ base: "19.5px", lg: "28px" }}
              letterSpacing={"0.05em"}
              fontWeight={700}
            >
              {props.title}
            </chakra.strong>
            <VStack
              textAlign="left"
              alignItems="flex-start"
              spacing={{ base: 1, lg: 2 }}
              color="2nd-gray"
            >
              <chakra.div
                fontFamily="serif"
                fontSize={{ base: "15px", lg: "18px" }}
                lineHeight={{ base: "16px", lg: "19.8px" }}
                fontWeight={700}
                letterSpacing="0.02em"
              >{`${props.givenName} ${props.familyName}`}</chakra.div>
              <chakra.div
                textStyle={"body-medium-13pt"}
                fontSize={{ base: "11px", lg: "13px" }}
                lineHeight={{ base: "16px", lg: "19.8px" }}
              >
                {props.team && <chakra.p> {props.team}</chakra.p>}
                <chakra.p>{props.jobTitle}</chakra.p>
              </chakra.div>
              <Flex alignItems="center">
                <PlaceIcon w={"9px"} h={"13px"} mr={1} />
                <chakra.p
                  textStyle={"body-medium-13pt"}
                  fontSize={{ base: "11px", lg: "13px" }}
                >
                  {props.placeJa}
                </chakra.p>
              </Flex>
            </VStack>
          </Box>
        </chakra.div>
      </Link>
    </NextLink>
  )
}
